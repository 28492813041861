import React from "react"
import Animation from "./animation"

import { use100vh } from "react-div-100vh"

import * as heroStyles from "./hero.module.css"

const Hero = () => {
  const height = use100vh()
  const nineTenthHeight = height ? height * 0.9 : "90vh"

  return (
    <div
      className={heroStyles.heroContainer}
      style={{ height: nineTenthHeight }}
    >
      <h1 className={heroStyles.heroText}>
        <span className={heroStyles.a}>a</span>
        <span className={heroStyles.digital}>digital</span>
        <span className={heroStyles.creative}>creative.</span>
      </h1>
      <div className={heroStyles.imageWrapper}>
        <Animation />
      </div>
    </div>
  )
}

export default Hero
