import React from "react"
import { useState, useEffect } from "react"
import Lottie from "react-lottie-player"
import Placeholder from "../assets/placeholder.json"

import { animation } from "./animation.module.css"

const Animation = () => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import("../assets/newanimation.json").then(setAnimationData)
  }, [])

  if (!animationData)
    return <Lottie className={animation} animationData={Placeholder} />
  return (
    <Lottie className={animation} animationData={animationData} play loop />
  )
}

export default Animation
